@mixin animation-transform() {
  transition: transform 2s;
}

@mixin animation-ease($item) {
  animation: $item 5s ease;
}

@mixin move-bottom-animate($start, $end) {
  @keyframes move-bottom-animate {
    0% {
      bottom: $start;
    }
    100% {
      bottom: $end;
    }
  }
}
