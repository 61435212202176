
              @import "@/assets/css/variables.scss";
            






















































































































































































@import '@/assets/css/components/promotion/agreeTnc.scss';
@import '@/assets/css/components/promotion/blueHeadTable.scss';

/deep/ .el-dialog__body {
  padding-top: 20px !important;
}
/deep/ .header-text {
  margin-bottom: 16px !important;
}
.text-uppercase {
  text-transform: uppercase;
}

/deep/ .el-dialog__body .top li {
  padding-bottom: 20px !important;
}

.common-dialog-wrapper /deep/ {
  .dialog-body {
    padding: 0 0 0 0;
  }
}

/deep/ .el-table {
  th {
    font-size: 12px;
  }
}
/deep/ .el-table.desc-table th:nth-child(1) .cell {
  border-top-right-radius: 0;
  border-top-left-radius: 8px;
}
/deep/ .el-table.desc-table th:nth-child(2) .cell {
  border-top-right-radius: 8px;
  border-top-left-radius: 0;
  border-left: 0.1px solid $white !important;
}

.deposit-container {
  display: flex;
  justify-content: space-around;
}
.gifts-container {
  font-size: 12px;
  text-align: left;
  margin-left: 8px;
}

@media (min-width: 640px) {
  /deep/ .el-table {
    th {
      font-size: 15px;
    }
  }
}
