
              @import "@/assets/css/variables.scss";
            





















































































































@import '@/assets/css/components/dialog/conditionDialog.scss';

.result-title {
  font-size: 22px;
  font-weight: 900;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 28px;
}
.result-prize {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: center;
  color: $dark-gray;
  height: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;
}

.transaction-container {
  margin-bottom: 16px;
}

// table style
/deep/ .el-table {
  th {
    height: auto;
    background-color: $white;
    color: $black;
    font-weight: 900;
    .cell {
      padding: 0;
    }
  }

  td {
    height: auto;
    border-bottom: none;
  }
}
/deep/ .el-table th {
  height: auto;
  .cell {
    padding: 0;
  }
}

.text-left {
  text-align: left;
}

// dialog
/deep/ .el-dialog__body {
  padding: 0 20px !important;

  .dialog-body {
    padding: 28px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

@media (min-width: 550px) {
  /deep/ .el-dialog__body {
    .dialog-body {
      padding: 80px 40px 64px 40px;
    }
  }

  .result-title {
    font-size: 28px;
  }
}

@media (min-width: 767px) {
  /deep/ .el-dialog__body {
    padding: 0 20px !important;
  }
}
