/deep/ .table-container {
  .table-title {
    display: inline-block;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 900;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    color: $black;
    text-align: left;
    &.blue-underline {
      margin-bottom: 48px;
      &:after {
        content: '';
        width: auto;
        height: 2px;
        margin: 16px auto 0;
        display: block;
        background-color: $primary;
      }
    }
  }
}

/deep/ .el-table.desc-table {
  $border-width: 1px;
  $border-color: $pu-dark-gray;
  th {
    height: auto;
    border-left: $border-width/2 solid $border-color;
    border-right: $border-width/2 solid $border-color;
    background-color: $future-blue;
    .cell {
      background-color: $future-blue;
      color: $white;
      width: 100%;
      padding: 8px;
      text-align: center;
    }
    &:nth-child(1) {
      @include rtl-sass-value(border-top-left-radius, 8px, 0);
      @include rtl-sass-value(border-top-right-radius, 0, 8px);

      @include rtl-sass-prop(border-left, border-right, $border-width solid $future-blue);
    }
    &:nth-last-child(2) {
      @include rtl-sass-value(border-top-left-radius, 0, 8px);
      @include rtl-sass-value(border-top-right-radius, 8px, 0);

      @include rtl-sass-prop(border-right, border-left, $border-width solid $future-blue);
    }
  }
  td {
    border-left: $border-width/2 solid $border-color;
    border-right: $border-width/2 solid $border-color;
    border-bottom: $border-width solid $border-color;

    &:nth-child(1) {
      @include rtl-sass-prop(border-left, border-right, $border-width solid $border-color);
    }
    &:nth-last-child(1) {
      @include rtl-sass-prop(border-right, border-left, $border-width solid $border-color);
    }
  }
}

/deep/ .el-table.head-gutter {
  th {
    height: auto;
    padding-left: 0.75%;
    padding-right: 0.75%;

    &:nth-child(1) {
      @include rtl-sass-value(padding-right, 1%, 0);
      @include rtl-sass-value(padding-left, 0, 1%);
    }
    &:nth-last-child(2) {
      @include rtl-sass-value(padding-right, 0, 1%);
      @include rtl-sass-value(padding-left, 1%, 0);
    }
    .cell {
      background-color: $future-blue;
      color: $white;
      border-radius: 8px 8px 0 0;
      width: 100%;
      padding: 18px 24px;
      text-align: center;
    }
  }
  td {
    border: none;
  }
}

.el-table /deep/ {
  .el-table__body-wrapper {
    background-color: $white;
  }
  tr:hover > td {
    background-color: $white !important;
  }
}

@media (min-width: 640px) {
  .table-title {
    font-size: 20px !important;
  }
}

@media (min-width: 1024px) {
  .table-title {
    font-size: 24px !important;
  }
}
