
              @import "@/assets/css/variables.scss";
            













































































@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.table-title.with-account {
  margin-bottom: 8px;
}

.account-text {
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 900;
  color: $black;
}

.title-desc {
  text-transform: none;
  font-weight: 300;
  font-size: 16px;
  margin-bottom: 20px;
  color: $black;
}
