.bn-container.xmasMakeAWish {
  margin: 0;
  max-width: none;
  width: 100%;
  position: relative;
  display: flex;
  @include rtl-sass-value(justify-content, start, end);
  .bn-info {
    width: 100%;
    margin: 0;
    padding: 48px 0 0 70px;
    // @include rtl-sass-value(padding, 48px 0 0 70px, 48px 70px 0 0);
    display: flex;
    // justify-content: flex-start;
    @include rtl-sass-value(justify-content, start, end);
    h3 {
      line-height: 1.2;
      /deep/ .title {
        font-size: 48px;
        text-align: start;
      }
      /deep/ .desc {
        font-weight: 400;
        font-size: 28px;
        text-align: start;
        margin-top: 12px;
      }
    }
  }

  &.xmasMakeAWish_vi {
    .bn-info {
      h3 {
        /deep/ .title {
          font-size: 44px;
        }
        /deep/ .desc {
          font-size: 24px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .bn-container {
    &.xmasMakeAWish {
      .bn-info {
        max-width: 500px;
      }

      &.xmasMakeAWish_fr_FR,
      &.xmasMakeAWish_pt,
      &.xmasMakeAWish_ko,
      &.xmasMakeAWish_ja,
      &.xmasMakeAWish_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 40px;
            }
          }
        }
      }

      &.xmasMakeAWish_es,
      &.xmasMakeAWish_vi,
      &.xmasMakeAWish_de {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 36px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .bn-container {
    &.xmasMakeAWish {
      .bn-info {
        max-width: 410px;
        padding: 48px 0 0 0 !important;
        margin: auto;
        h3 {
          /deep/ .title {
            font-size: 36px;
          }
          /deep/ .desc {
            font-size: 22px;
          }
        }
      }

      &.xmasMakeAWish_fr_FR,
      &.xmasMakeAWish_ja,
      &.xmasMakeAWish_vi,
      &.xmasMakeAWish_de,
      &.xmasMakeAWish_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 32px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .bn-container {
    &.xmasMakeAWish {
      .bn-info {
        padding: 12px 0 0 0 !important;
        width: 280px;
        margin: auto;
        h3 {
          /deep/ .title {
            font-size: 28px !important;
            text-align: center;
            display: inline-block;
          }
          /deep/ .desc {
            font-size: 16px !important;
            text-align: center;
            display: inline-block;
            width: 100%;
          }
        }
      }

      &.xmasMakeAWish_fr_FR,
      &.xmasMakeAWish_es,
      &.xmasMakeAWish_pt,
      &.xmasMakeAWish_ja,
      &.xmasMakeAWish_vi,
      &.xmasMakeAWish_de,
      &.xmasMakeAWish_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 24px !important;
            }
            /deep/ .desc {
              font-size: 16px !important;
            }
          }
        }
      }
    }
  }
}
