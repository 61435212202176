.bn-container {
  max-width: 1600px;
  margin: auto;

  .bn-info {
    @include rtl-sass-value(max-width, 780px, none);
    vertical-align: middle;
    margin: 35px 70px 0;
    text-align: left;

    h3 {
      font-size: 40px;
      font-weight: 800;
      line-height: 1.5;
      text-align: left;
      color: $white;

      /deep/ .bg_yellow {
        color: #ffcb00;
        font-size: 56px;
      }
    }
  }

  .r-side-img {
    position: relative;
    top: -250px;
    right: 5%;
    max-width: max-content;
    z-index: -999;
    float: right;
  }
}

//deposit20bns
.bn-container.deposit20bns_vi {
  .bn-info {
    h3 {
      font-weight: 600;
      line-height: 1.45;
    }
  }
}

//tradingBonus
.bn-container.tradingBonus {
  .bn-info {
    h3 {
      color: $black;

      /deep/ .bg_blue {
        color: $sky-blue;
        font-size: 80px;
      }
    }
  }
  .r-side-img {
    top: -215px;
  }
}

// cashback
.bn-container {
  &.cashback {
    margin: 0;
    max-width: none;
    width: 100%;
    position: relative;

    .bn-info {
      margin: 0;
      @include rtl-sass-value(padding, 48px 0 0 70px, 48px 70px 0 0);
      h3 {
        @include rtl-sass-value(text-align, left, right);
        line-height: 1.2;
        /deep/ .title {
          font-size: 48px;
        }
        /deep/ .desc {
          font-weight: 400;
          font-size: 28px;
        }
      }
    }
    .r-side-img {
      position: absolute;
      top: 0;
      @include rtl-sass-value(right, 0, unset);
      @include rtl-sass-value(left, unset, 0);
      max-width: none;
      > img {
        height: 255px;
        width: auto;
        @include rtl-sass-prop-sd(transform, scaleX(-1));
      }
    }
  }

  &.cashback-act {
    .bn-info {
      margin: 35px 70px 0;
      h3 {
        line-height: 1.2;
        /deep/ .title {
          font-size: 48px;
        }
        /deep/ .desc {
          font-weight: 400;
          font-size: 28px;
        }
      }
    }
    .r-side-img {
      right: 0;
      top: -248px;
      max-width: 512px;
    }

    &.cashback-act_fr_FR,
    &.cashback-act_es {
      .bn-info {
        max-width: 640px;
        h3 {
          /deep/ .desc {
            font-size: 24px;
          }
        }
      }
    }
    &.cashback-act_vi {
      .bn-info {
        h3 {
          /deep/ .title {
            font-size: 36px;
          }
          /deep/ .desc {
            font-size: 24px;
          }
        }
      }
    }
    &.cashback-act_ar {
      .bn-info {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1200px) {
  .bn-container {
    .bn-info {
      max-width: 600px;

      h3 {
        font-size: 30px;
        line-height: 1.3;
        /deep/ .bg_yellow {
          font-size: 40px;
        }
      }
    }

    .r-side-img {
      top: -130px;
      max-width: 300px;
    }
  }

  .bn-container.deposit20bns_vi {
    .bn-info {
      h3 {
        line-height: 1.15;
      }
    }
  }

  .bn-container.tradingBonus {
    .bn-info {
      h3 {
        /deep/ .bg_blue {
          font-size: 60px;
        }
      }
    }
    .r-side-img {
      top: -70px;
      max-width: 350px;
    }
  }

  .bn-container {
    &.cashback-act {
      .r-side-img {
        top: -270px;
        max-width: 508px;
      }

      &.cashback-act_fr_FR,
      &.cashback-act_es {
        .bn-info {
          h3 {
            /deep/ .desc {
              font-size: 20px;
            }
          }
        }
      }
      &.cashback-act_th,
      &.cashback-act_de,
      &.cashback-act_ja {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 36px;
            }
            /deep/ .desc {
              font-size: 24px;
            }
          }
        }
      }
      &.cashback-act_vi,
      &.cashback-act_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 32px;
            }
            /deep/ .desc {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bn-container .bn-info {
    h3 {
      font-size: 32px;
    }
  }
  .bn-container.tradingBonus {
    .bn-info {
      h3 {
        font-size: 30px;
      }
    }
    .r-side-img {
      top: -40px;
      max-width: 300px;
    }
  }

  .bn-container {
    &.cashback-act {
      .bn-info {
        margin: 28px auto 0 40px;
        h3 {
          text-align: left;
          /deep/ .title {
            font-size: 36px;
          }
          /deep/ .desc {
            font-size: 24px;
          }
        }
      }
      .r-side-img {
        top: -148px;
      }

      &.cashback-act_fr_FR,
      &.cashback-act_es {
        .bn-info {
          h3 {
            /deep/ .desc {
              font-size: 20px;
            }
          }
        }
      }
      &.cashback-act_vi,
      &.cashback-act_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 28px;
            }
            /deep/ .desc {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .bn-container {
    .bn-info {
      margin: 5px auto 0;
      h3 {
        font-size: 20px;
        text-align: center;
        /deep/ .bg_yellow {
          font-size: 25px;
        }
      }
    }
    .r-side-img {
      top: -40px;
      right: auto;
      float: none;
      margin: auto;
    }
  }

  .bn-container.tradingBonus {
    .bn-info {
      max-width: 150px;
      h3 {
        font-size: 20px;
        /deep/ .bg_blue {
          font-size: 40px;
        }
      }
    }
    .r-side-img {
      top: 0px;
    }
  }

  .bn-container {
    &.cashback {
      .bn-info {
        @include rtl-sass-value(padding, 48px 0 0 36px, 48px 36px 0 0);
        h3 {
          /deep/ .title {
            font-size: 36px;
          }
          /deep/ .desc {
            font-size: 22px;
          }
        }
      }
    }

    &.cashback-act {
      .bn-info {
        max-width: 388px;
      }
      .r-side-img {
        top: -128px;
        right: 0;
        float: right;
        margin: 0;
        width: 508px;
      }

      &.cashback-act_fr_FR,
      &.cashback-act_es {
        .bn-info {
          h3 {
            /deep/ .desc {
              font-size: 20px;
            }
          }
        }
      }
      &.cashback-act_ko,
      &.cashback-act_zh_CN,
      &.cashback-act_de,
      &.cashback-act_id {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 28px;
              display: block;
            }
            /deep/ .desc {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .bn-container {
    &.cashback {
      .bn-info {
        @include rtl-sass-value(padding, 12px 0 0 28px, 12px 28px 0 0);
        width: 192px;
        h3 {
          /deep/ .title {
            font-size: 32px;
          }
          /deep/ .desc {
            font-size: 18px;
          }
        }
      }
      .r-side-img {
        display: none;
      }

      &.cashback_th,
      &.cashback_vi,
      &.cashback_de {
        .bn-info {
          h3 {
            /deep/ .title {
              font-size: 28px;
            }
            /deep/ .desc {
              font-size: 16px;
            }
          }
        }
      }
    }

    &.cashback-act {
      .bn-info {
        margin: 44px auto 0 28px;
        max-width: 228px;
        h3 {
          line-height: 1;
          /deep/ .title {
            font-size: 24px;
            display: block;
          }
          /deep/ .desc {
            font-size: 16px;
          }
        }
      }
      .r-side-img {
        display: none;
      }

      &.cashback-act_fr_FR {
        .bn-info {
          h3 {
            /deep/ .desc {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
