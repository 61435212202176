
              @import "@/assets/css/variables.scss";
            








































@import '@/assets/css/components/promotion/blueHeadTable.scss';
/deep/ .el-table.head-gutter td {
  border-bottom: 1px solid #ebeef5;
}

.table-title.with-account {
  margin-bottom: 16px;
}
.account-context {
  margin-top: 4px;
  margin-bottom: 16px;
}

.small-table {
  width: 100%;
  margin: auto;
}

@media (min-width: 537px) {
  .small-table {
    width: 480px;
    margin: auto;
  }
}
