
              @import "@/assets/css/variables.scss";
            


























































































.text-result {
  margin-top: 24px;
  margin-bottom: 24px;
  color: $black;
  line-height: 1.5;
  font-size: 16px;
  text-align: center;

  &.success {
    padding: 0 14%;
  }
  &.failOptout {
    padding: 0 10%;
  }
}

.common-dialog-wrapper /deep/ .dialog {
  .el-dialog__body {
    padding: 0 68px;
    .dialog-body {
      padding: 0 0 45px 0;
      max-height: 310px;
    }
  }
}

@media (max-width: 767px) {
  .common-dialog-wrapper /deep/ .dialog {
    .el-dialog__body {
      padding: 30px 20px;
    }
  }
}
