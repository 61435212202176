
              @import "@/assets/css/variables.scss";
            



































.desc-container {
  text-align: start !important;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 1.5;
  margin-bottom: 24px;
}
.desc-title {
  font-weight: bold;
  margin-bottom: 4px;
}
.desc-context {
  margin-left: 16px;
  li {
    list-style: decimal;
    font-size: 14px;
    line-height: 1.8;
  }
}
