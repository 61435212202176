@import '@/assets/css/components/dialog/conditionDialog.scss';

/deep/ .font-bold {
  font-weight: bold;
}
.mb-24 {
  margin-bottom: 24px;
}
.start-container {
  position: relative;
  overflow: hidden;
  background-image: url('~@/assets/images/promotion/luckyDraw/bg-sm.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;
  border-radius: 20px;
  padding: 28px;
}

.prize {
  position: absolute;
  @include rtl-sass-value(left, 30px, auto);
  @include rtl-sass-value(right, auto, 30px);
  z-index: 2;
  height: 280px;
  width: 78%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin: 0px 10px;
  &-title {
    font-size: 20px;
    font-weight: 800;
  }
  .button-box {
    margin-top: 16px;
    align-self: center;
  }
  ol {
    margin-top: 16px;
    padding-left: 12px;
  }
  li {
    list-style: disc;
    font-size: 12px;
    line-height: 1.8;
  }

  &-general-title {
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 4px;
  }
  &-general-content {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
  }
}

.main-container {
  width: 100%;
}

.hat-image {
  position: absolute;
  bottom: -30%;
  right: 50%;
  transform: translateX(50%);

  height: auto;
  min-width: 500px;
  &.ballAT {
    right: 50%;
    transform: rotate(-0.08turn);
    animation: move-bottom-animate 0.2s infinite alternate, shake-animate 0.3s ease-out infinite alternate;
  }
}

@include move-bottom-animate(-30%, -20%);

@keyframes shake-animate {
  0% {
    transform: translateX(50%) rotate(0);
  }
  10% {
    transform: translateX(50%) rotate(-0.08turn);
  }
  30% {
    transform: translateX(50%) rotate(0);
  }
  50% {
    transform: translateX(50%) rotate(-0.1turn);
  }
  70% {
    transform: translateX(50%) rotate(0);
  }
  100% {
    transform: translateX(50%) rotate(-0.06turn);
  }
}
@keyframes shake-animate-lg {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-0.08turn);
  }
  30% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(-0.1turn);
  }
  70% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-0.06turn);
  }
}

.result-title {
  font-size: 28px;
  font-weight: 900;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  color: $black;
  margin-bottom: 28px;
}
.result-prize {
  font-size: 20px;
  font-weight: 800;
  line-height: 28px;
  text-align: center;
  color: $dark-gray;
}

.action-container {
  margin-top: 56px;
}

@media (min-width: 476px) {
  .start-container {
    height: 550px;
  }
  .hat-image {
    bottom: -35%;
  }
  @include move-bottom-animate(-35%, -25%);

  .prize {
    &-title {
      font-size: 24px;
    }
    .button-box {
      margin-top: 16px;
    }
    ol {
      margin-top: 20px;
      padding-left: 16px;
    }
    li {
      font-size: 16px;
    }
  }
}
@media (min-width: 640px) {
  .start-container {
    height: 630px;
    background-image: url('~@/assets/images/promotion/luckyDraw/bg.png');
  }
  .prize {
    margin: 10px 30px 0;
    &-title {
      font-size: 30px;
      &.th,
      &.vi,
      &.fr_FR {
        font-size: 26px;
      }
      &.ar {
        text-align: right;
      }
    }

    &-general-title {
      font-size: 52px;
    }
    &-general-content {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .hat-image {
    min-width: 700px;
    &.ballAT {
      right: 50%;
    }
  }
}
@media (min-width: 890px) {
  .prize {
    width: 80%;
    .button-box {
      margin-top: 32px;
    }
    ol {
      margin-top: 24px;
      padding-left: 20px;
    }
    li {
      font-size: 20px;
    }
  }
  .hat-image {
    bottom: -45%;
    width: 650px;
    &.ballAT {
      right: 50%;
    }
  }
  @include move-bottom-animate(-45%, -35%);
}
@media (min-width: 1200px) {
  .start-container {
    height: 340px;
    background-image: url('~@/assets/images/promotion/luckyDraw/bg-lg.png');
  }

  .prize {
    width: 70%;
    .button-box {
      align-self: auto;
      margin-top: 16px;
    }
    ol {
      margin-top: 12px;
      padding-left: 20px;
    }

    &-general-title {
      font-size: 32px;
      @include rtl-sass-value(text-align, left, right);
    }
    &-general-content {
      font-size: 24px;
      line-height: 36px;
      @include rtl-sass-value(text-align, left, right);
    }

    li.plenty-prizes {
      font-size: 16px;
    }
  }

  .main-container {
    width: auto;
  }

  .hat-image {
    bottom: -65%;
    @include rtl-sass-value(right, -12%, auto);
    @include rtl-sass-value(left, auto, -12%);

    transform: translateX(0%);
    width: 650px;
    min-width: 650px;
    &.ballAT {
      margin-right: unset;

      @include rtl-sass-value(right, -12%, auto);
      @include rtl-sass-value(left, auto, -12%);

      transform: translateX(0%);
      animation: move-bottom-animate 0.2s infinite alternate, shake-animate-lg 0.3s ease-out infinite alternate;
    }
  }
  @include move-bottom-animate(-65%, -55%);

  .context-container {
    width: 320px;
  }
}
@media (min-width: 1536px) {
  .hat-image {
    @include rtl-sass-value(right, -10%, auto);
    @include rtl-sass-value(left, auto, -10%);
    &.ballAT {
      @include rtl-sass-value(right, -10%, auto);
      @include rtl-sass-value(left, auto, -10%);
    }
  }
}

// dialog
.common-dialog-wrapper /deep/ {
  .dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
    // top: 15vh;
    top: 6vh;
  }
  .dialog-body {
    padding: 80px 40px 64px 40px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .el-dialog__body p {
    padding: 0 4% !important;
  }
  .close-btn {
    top: calc(50%) !important;
  }
}
@media (min-width: 550px) {
  .common-dialog-wrapper /deep/ {
    .close-btn {
      top: calc(50%) !important;
    }

    .el-dialog__body p {
      padding: 0 20% !important;
    }
  }
}
@media (min-width: 768px) {
  .common-dialog-wrapper /deep/ {
    .dialog.el-dialog__wrapper .el-dialog.el-dialog--center {
      top: 50%;
    }
    .close-btn {
      top: calc(50%) !important;
      left: calc(50% - 25px);
    }
  }
}
