.banner-container {
  position: relative;
  text-align: center;
  min-height: 255px;
  .banner-image {
    width: 100%;
  }
  .new-banner-image {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-size: cover;
    background-position: right;
    // @include rtl-sass-value(background-position, right, left);
    background-repeat: no-repeat;
  }
}

.inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.over-bonus-alert {
  text-align: center;
  margin-top: 25px;
  padding: 0 26px;
}

.meta-box {
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: $white;
  /deep/ {
    .title-container {
      text-align: center;
    }
    .title {
      display: inline-block;
      text-align: center;
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 900;
      font-size: 20px;
      line-height: 40px;
      color: $black;
      &:after {
        content: '';
        width: auto;
        height: 2px;
        margin: 16px auto 0;
        display: block;
        background-color: $future-blue;
      }
    }
    .sub-title {
      color: $future-blue;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      font-size: 16px;
      margin: auto;
      &.inactive {
        max-width: 480px;
      }
    }
    .button-box {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      min-width: 200px;

      .btn-blue {
        border-radius: 4px;
        min-width: 162px;
        font-size: 16px;
        text-transform: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &.long-button {
          min-width: 300px;
          width: auto;
        }
      }
    }

    .sub-box1 {
      padding-bottom: 52px;
      border-bottom: 1px dotted rgb(201, 201, 201);
    }

    .sub-box2 {
      padding-top: 52px;
      padding-left: 8px;
      padding-right: 8px;
    }

    .cashback-container {
      // width: 100%;
      margin-top: 52px !important;
      width: 360px;
      margin: auto;
    }

    .opt-button-box {
      margin-top: 32px;
    }

    .agreeTnc-box {
      margin-bottom: 36px;
      display: flex;
      justify-content: center;
      padding-left: 36px;
      font-size: 16px;
      font-weight: 200;
      color: $black;
      margin-top: 20px;
      a {
        font-size: 14px;
        font-weight: 200;
        color: $future-blue;
        border-bottom: 1px solid $future-blue;
        padding: 0 0 1px;
      }
    }

    .checkbox {
      text-align: end;
      .el-checkbox__inner {
        width: 20px;
        height: 20px;
        border-radius: unset;
        border: 1px solid #0051ff;
        &::after {
          height: 14px;
          width: 6px;
          left: 6px;
          top: -2px;
        }
      }
    }
  }
}

.table-wrapper {
  width: 100%;
  padding: 0px;
  margin-top: 48px;
}

.table-item {
  & + & {
    margin-top: 60px;
  }
}

@media (min-width: 550px) {
  .table-wrapper {
    margin-top: 64px;
  }
}

@media (min-width: 640px) {
  .meta-box /deep/ {
    padding: 48px;
    .title {
      font-size: 20px;
    }
    .cashback-container {
      width: 540px;
    }
    .sub-box2 {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@media (min-width: 768px) {
  .meta-box /deep/ {
    padding: 60px;
    margin: 0 24px;
  }

  .table-wrapper {
    padding: 24px;
  }

  .over-bonus-alert {
    font-size: 16px;
    color: #7a8190;
    margin: 32px 50px 0;
  }
}

@media (min-width: 1024px) {
  .meta-box /deep/ {
    padding: 60px 80px;
    margin: 0 50px;
    .title {
      font-size: 24px;
      line-height: 50px;
    }

    .button-box {
      .btn-blue {
        font-size: 20px;
      }
    }
  }

  .table-wrapper {
    padding: 50px;
  }
}

@media (min-width: 1536px) {
  .meta-box /deep/ {
    margin: 0 60px;
    padding: 60px 120px;
  }

  .table-wrapper {
    padding: 60px;
  }
}
