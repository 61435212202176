
              @import "@/assets/css/variables.scss";
            


















































































































































@import '@/assets/css/components/promotion/blueHeadTable.scss';
.amount {
  font-weight: 800;
  font-size: 30px;
  line-height: 40px;
}
.rewards {
  height: 60px;
  border-bottom: 1px solid #f3f4f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reward-container {
  max-height: 200px;
  min-height: 60px;
  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.overflow-y-auto {
    overflow-y: auto;
  }
}
.redeem-btn {
  width: 150px;
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  &.redeemed {
    font-weight: 800;
    color: $dark-gray;
  }
}

.gift-physical-no-data {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hide-block {
  width: 150px;
  height: 40px;
}

.title-container {
  .title-desc {
    text-transform: none;
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 4px;
    color: $black;
    &:last-child {
      margin-bottom: 20px;
    }
  }
}
