
              @import "@/assets/css/variables.scss";
            































































/deep/ .tnc {
  margin-top: 60px;
  margin-bottom: 56px;
}
